import { Link } from "gatsby"
import React from "react"
import { Column, Container, Row } from "../components/Grid"
import Hero from "../components/Hero"
import Image from "../components/Image"
import Layout from "../components/Layout"
import MultiVideoPlayer from "../components/MultiVideoPlayer"
import Action from "../components/Resource/Action"
import Section from "../components/Section"
import SEO from "../components/Seo"
import educatorGuide from "../docs/Unilever-AmazingMe-CompanionGuide-US.pdf"
import educatorGuideColombia from "../docs/VFT/DE_Unilever-AmazingMe-Companion Guide VFT_Columbian Spanish.pdf"
import educatorGuideMexico from "../docs/VFT/DE_Unilever-AmazingMe-Companion Guide VFT_Mexican Spanish.pdf"
import educatorGuideArgentina from "../docs/VFT/DE_Unilever-AmazingMe-Companion Guide VFT_Neutral Spanish (Argentina-Chile-LatAm).pdf"
import FooterCallout from "../partials/footerCallout"

const StudentsPage = () => (
  <Layout>
    <SEO title="Virtual Field Trip" />
    {/***************  HERO ***************/}
    <Section className="hero hero__vft">
      <Hero
        title={
          <div>
            <p className="header-bar__grade">Grades 4&mdash;5</p>
            <p className={"header-bar--yellow"}>Virtual Field Trip</p>
            <p className="header-bar__subhead">
              Unmask the true meaning of beauty.
            </p>
          </div>
        }
        body=""
        image="img-vft-resources-lead.png"
      />
    </Section>

    {/***************  RESOURCE HORIZONTAL ***************/}
    <Section className="resource-container">
      <Container>
        <h2>Body Confidence Virtual Field Trip</h2>
        <p>
          In this Virtual Field Trip, elementary students will learn from their
          peers how to get smarter about images they see online, build
          strategies to fight weight-based bullying and teasing and appreciate
          their awesome selves!
        </p>
        <Row>
          <Column span={12} className="">
            {/* <MultiVideoPlayer
              videos={[
                {
                  azure_src:
                    "https://primaryusproduction-use2.streaming.media.azure.net/c41d6711-c930-40ba-9ec0-9f7dbb42129a/Unilever-VFT-ma.ism/manifest",
                  title: "Full VFT",
                  posterSrc: vftPoster,
                  thumbnail: "theme/poster/Full-VFT.jpg",
                  ccLang: "en-US,es-MX",
                  assetId: "01130207-5b06-4667-b620-1c47e27d759f",
                },
                {
                  azure_src:
                    "https://primaryusproduction-use2.streaming.media.azure.net/f9092f12-7129-416e-a8e9-6a02c8c06e87/1WhatisBeautymp.ism/manifest",
                  title: "Chapter 1: What is Beauty?",
                  posterSrc: chapter1,
                  thumbnail: "theme/poster/Ch-1.jpg",
                  ccLang: "en-US,es-MX",
                  assetId: "2416a927-c1d8-4966-809c-9b60ef09119d",
                },
                {
                  azure_src:
                    "https://primaryusproduction-use2.streaming.media.azure.net/ef01121b-61f7-4863-bc54-043b931ae752/2BuildingAPosti.ism/manifest",
                  title: "Chapter 2: Building a Positive Body Image",
                  posterSrc: chapter2,
                  thumbnail: "theme/poster/Ch-2.jpg",
                  ccLang: "en-US,es-MX",
                  assetId: "a3b36244-7fd5-4153-b648-62f7322ff03d\n",
                },
                {
                  azure_src:
                    "https://primaryusproduction-use2.streaming.media.azure.net/3f9fe82d-ea90-4f5f-bc60-d1a7165a9628/3AvoidBodyTalkm.ism/manifest",
                  title: "Chapter 3: Avoiding Body Talk",
                  posterSrc: chapter3,
                  thumbnail: "theme/poster/Ch-3.jpg",
                  ccLang: "en-US,es-MX",
                  assetId: "05b47c49-18f6-4000-a40c-d21d90a6aae9",
                },
                {
                  azure_src:
                    "https://primaryusproduction-use2.streaming.media.azure.net/54c54cab-146c-4848-84e5-c2fe9491f4f4/4LearningBodyPo.ism/manifest",
                  title: "Chapter 4: Learning Body Positivity",
                  posterSrc: chapter4,
                  thumbnail: "theme/poster/Ch-4.jpg",
                  ccLang: "en-US,es-MX",
                  assetId: "4445a087-7f36-45cf-96f5-4d733c1fcd68",
                },
                {
                  azure_src:
                    "https://primaryusproduction-use2.streaming.media.azure.net/ba746576-2e41-488a-83ff-c8c7b8ec9bca/5FocusOnBodyStr.ism/manifest",
                  title: "Chapter 5: Focus on Body Strength",
                  posterSrc: chapter5,
                  thumbnail: "theme/poster/Ch-5.jpg",
                  ccLang: "en-US,es-MX",
                  assetId: "5d25881e-9e40-462c-b351-0a786cab1582",
                },
                {
                  azure_src:
                    "https://primaryusproduction-use2.streaming.media.azure.net/e3616c11-ace6-4bf2-9997-40b33f07275f/6BodyConfidence.ism/manifest",
                  title: "Chapter 6: Body Confidence",
                  posterSrc: chapter6,
                  thumbnail: "theme/poster/Ch-6.jpg",
                  ccLang: "en-US,es-MX",
                  assetId: "32d7b085-ef09-4fee-8913-61e6cc532521",
                },
              ]}
            /> */}
            <MultiVideoPlayer
              videos={[
                {
                  guidSrc: "01130207-5b06-4667-b620-1c47e27d759f",
                  poster: "theme/poster/Full-VFT.jpg",
                },
                {
                  guidSrc: "a0500aea-1d7f-48a1-9040-ff75160e5eaa",
                  poster: "theme/poster/Ch-1.jpg",
                },
                {
                  guidSrc: "5ab68eeb-cfaf-434e-b51c-03dc6167dd0b",
                  poster: "theme/poster/Ch-2.jpg",
                },
                {
                  guidSrc: "45e7f6d6-8acf-45fc-b6df-d0cad26b6c3c",
                  poster: "theme/poster/Ch-3.jpg",
                },
                {
                  guidSrc: "7ac2f7cb-1cdd-499b-852b-49c4b85f5d98",
                  poster: "theme/poster/Ch-4.jpg",
                },
                {
                  guidSrc: "8f9b49b2-b574-4e01-ac61-d268d9745498",
                  poster: "theme/poster/Ch-5.jpg",
                },
                {
                  guidSrc: "85ea63f6-187c-4c34-935e-55a354906233",
                  poster: "theme/poster/Ch-6.jpg",
                },
              ]}
            />
          </Column>
        </Row>
        <Row>
          <Column span={6} className={""}>
            <p>
              Don’t forget to extend the learnings <strong>before</strong>,{" "}
              <strong>during</strong> and <strong>after</strong> the Virtual
              Field Trip with ready-to-go, standards-aligned activities! Explore
              the Educator Guide, available in English and Spanish, to get
              started.
            </p>
            <Action
              label="Educator Guide"
              size="(Select a Language)"
              type="dropdown"
              actions={[
                {
                  title: "VFT Ed Guide - English",
                  type: "download",
                  path: educatorGuide,
                  label: "English",
                },
                {
                  title: "VFT Ed Guide - Columbian_Spanish",
                  type: "download",
                  path: educatorGuideColombia,
                  label: "Spanish (Colombia)",
                },
                {
                  title: "VFT Ed Guide - Argentine_Spanish",
                  type: "download",
                  path: educatorGuideArgentina,
                  label: "Spanish (Latin America)",
                },
                {
                  title: "VFT Ed Guide - Mexican_Spanish",
                  type: "download",
                  path: educatorGuideMexico,
                  label: "Spanish (Mexico)",
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  EXPLORE ADDITIONAL RESOURCES ***************/}
    <Section className={"orange-squiggle"}>
      <Container>
        <h2>Explore Additional Resources</h2>
        <Row>
          <Column span={5} className={"is-offset-1 resource"}>
            <Image
              className={"resource-image"}
              filename={"img-promo-resources.png"}
            />
            <h4 className={"bold-text"}>Classroom Resources</h4>
            <p>
              Help your students realize their full potential with NEW classroom
              resources.
            </p>
            <Link to={"/classroom-resources"}>Learn More</Link>
          </Column>
          <Column span={5} className={"resource"}>
            <Image
              className={"resource-image"}
              filename={"img-promo-ATS.png"}
            />
            <h4 className={"bold-text"}>Animated Topic Series</h4>
            <p>
              The Amazing Me: Animated Topic Series encourages young people to
              realize their full potential and improve their body confidence and
              self-esteem.
            </p>
            <Link to={"/power-of-confidence/"}>Learn More</Link>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  BODY CONFIDENCE FACT ***************/}
    <Section className={"body-confidence-fact body-confidence-fact--vft "}>
      <Container>
        <Row>
          <Column span={7}>
            <h2>The impact of low self-esteem</h2>
            <p>
              Low self-esteem has been associated with a number of
              psychological, physical, and social consequences that may
              influence successful adolescent development and the transition to
              adulthood.
            </p>
            <p>
              &mdash;<em>National Institute of Health</em>
            </p>
          </Column>
          <Column
            span={4}
            className={"is-offset-1 body-confidence-fact__container"}
          >
            <div className={"body-confidence-fact__container__image"}>
              <Image filename={"img-subfooter-2.png"} className={""} />
            </div>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  FOOTER CALLOUT ***************/}
    <FooterCallout />
  </Layout>
)
export default StudentsPage
